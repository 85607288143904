import { Action, WishlistInitialState } from 'Shared/State';
import { addUserLog } from '@polarnopyret/scope';
import MiniWishListViewModel from 'WishList/MiniWishListViewModel.type';

export default function(state: MiniWishListViewModel, action: Action): MiniWishListViewModel {
  state = state || {
    ...WishlistInitialState,
  };
  switch (action.type) {
    case ADD_ITEM: {
      return {
        ...state,
        items: state.items.concat((action as AddItemAction).code),
      };
    }
    case REMOVE_ITEM: {
      return {
        ...state,
        items: state.items.filter(item => item !== (action as RemoveItemAction).code),
      };
    }
    default:
      return state;
  }
}

//Actions

export type AddItemAction = Action & {
  code: string;
};

export type RemoveItemAction = Action & {
  code: string;
};

export function AddItem(code: string) {
  addUserLog('Adding item to wishlist');
  return {
    type: ADD_ITEM,
    code,
  } as AddItemAction;
}

export function RemoveItem(code: string) {
  addUserLog('Removing item from wishlist');
  return {
    type: REMOVE_ITEM,
    code,
  } as RemoveItemAction;
}

export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';