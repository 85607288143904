import React, { useEffect, useState } from 'react';
import { styled, StyledComponent, StyledProps } from '@glitz/react';
import { Style, StyleOrStyleArray } from '@glitz/type';
import { addUserLog, post, pushState } from '@polarnopyret/scope';
import connect from 'Shared/connect';
import MainMenuItemType from './MainMenuItem.type';
import { MainMenuType } from 'Shared/State';
import { setMainMenuCategory, toggleMainMenu } from 'SiteLayout/MainMenu/action-creators';
import Link from '../../Shared/Link';
import { Right12x12, Right16x16 } from 'Shared/Icons/Arrow';
import { Location24x24 } from 'Shared/Icons/Location';
import { QuestionCircle24x24 } from 'Shared/Icons/QuestionCircle';
import MainMenuCategoryItem from 'SiteLayout/MainMenu/MainMenuCategoryItem.type';
import SecondaryMenuItem from 'SiteLayout/MainMenu/SecondaryMenuItem.type';
import { AccountBoxScene, openAccountBox } from 'SiteLayout/AccountBox/action-creators';
import { User24x24 } from 'Shared/Icons/User';
import { Logout12x12 } from 'Shared/Icons/Logout';
import MenuLinkItem from 'SiteLayout/MainMenu/MenuLinkItem.type';
import { Favourite24x24 } from 'Shared/Icons/Favorite';
import LinkItem from 'SiteLayout/Shared/LinkItem.type';
import { colorFromTag } from 'Shared/utils';
import Modal, { ModalScrollableContent } from 'Shared/SharedComponents/atoms/Modal';
import { startPage } from 'Shared/known-urls';
import * as style from 'Shared/Style';

type RequiredPropType = {};

type ConnectedPropType = {
  mainMenu: MainMenuType;
  toggleMainMenu: (item?: MainMenuItemType) => void;
  setMainMenuCategory: (item: MainMenuItemType) => void;
  openAccountBox: () => void;
};

type PropType = RequiredPropType & ConnectedPropType;

const boldUcase: StyleOrStyleArray<Style> = {
  fontWeight: 500,
  textTransform: 'uppercase',
};

const MenuLinkText = styled.span({
  ...boldUcase,
  fontSize: '16px',
  lineHeight: '120%',
  textAlign: 'left',
  display: 'inline-block',
  padding: { x: 0, y: 16 },
})

const ModalScrollableContentSecondTierDesktop = styled(ModalScrollableContent, {
  margin: { top: '16px', bottom: '16px' },
  padding: { top: '36px', bottom: '32px', x: '16px' }
})

export const margin = 8;

export const Divider = styled.hr({
  position: 'relative',
  backgroundColor: style.colors.monochrome.black,
  height: '2px',
  border: { xy: { width: 0 } },
});

const onClickOrRedirect = ({
  onClick,
  item,
  e,
}: {
  onClick: () => void;
  item: MainMenuCategoryItem | SecondaryMenuItem;
  e?: React.MouseEvent;
}) => {
  if (e) e.preventDefault();
  return item.children?.length !== 0 ? onClick : () => pushState(item.url);
};

const ItemLabel = styled.span({
  fontSize: '16px',
  fontWeight: 500,
  textAlign: 'left',
  margin: { x: 0, bottom: '6px', top: 0 },
  display: 'block',
})

const ItemHeading = styled.span({
  ...boldUcase,
  fontSize: '40px',
  lineHeight: '100%',
  textAlign: 'left',
  margin: { x: 0, bottom: '24px', top: 0 },
  display: 'block',
})

const TabWrap = styled.div({
  display: 'flex',
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  },
  paddingBottom: '8px',
  gap: '12px',
})

const TabText = styled.span({
  ...boldUcase,
  fontSize: '14px',
  lineHeight: '100%',
  textAlign: 'left',
  cursor: 'pointer',
  textDecoration: 'none',
  color: style.colors.monochrome.a11ygrey,
})

const TabTextSelected = styled(TabText, {
  color: style.colors.monochrome.black,
})

const AdditionalLinksWrap = styled.div({
  marginTop: '24px'
})

const LinkBase = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  }
})

const HoverLink = styled(LinkBase, {
  transition: {
    delay: 0,
    duration: '0.2s',
    property: 'padding-right',
    timingFunction: 'ease-in'
  },
  ':hover': {
    paddingRight: '8px'
  },
})

const HoverLinkActive = styled(HoverLink, {
  backgroundColor: style.colors.monochrome.extraLightGrey,
})

const HoverLinkImage = styled.img({
  height: '32px',
  width: '32px',
})

const HoverLinkImageActive = styled(HoverLinkImage, {
  margin: { right: '8px' },
})

const ExtraLinkBase = styled(Link, {
  ...boldUcase,
  fontSize: '20px',
  lineHeight: '100%',
  textDecoration: 'none',
});

const HoverExtraLink = styled(ExtraLinkBase, {
  display: 'flex',
  alignItems: 'center',
  ':hover': { color: style.hex2rgba(style.colors.monochrome.black, 0.7) },
  transition: {
    delay: 0,
    duration: '0.3s',
    property: 'color',
    timingFunction: 'ease-in'
  },
  marginBottom: '8px',
  // @ts-ignore
  ':hover span': { marginLeft: '16px', color: style.hex2rgba(style.colors.monochrome.black, 0.7) },
})

const HoverExtraLinkIcon = styled(Right16x16, {
  marginLeft: '8px',
  transition: {
    delay: 0,
    duration: '0.3s',
    property: 'color, margin-left',
    timingFunction: 'ease-in'
  },
})

const ExtraLink = styled(ExtraLinkBase, {
  display: 'block',
  marginBottom: '8px',
})

export const CategoryModalContent = (
  {
    isOpen,
    selectedTabIndex,
    selectedTopItem,
    setSelectedTabIndex,
    activeMenuItem,
    setSelectedMenuItem,
  }: {
    isOpen: boolean;
    selectedTabIndex: number;
    selectedTopItem: MainMenuItemType;
    setSelectedTabIndex: (nr: number) => void;
    activeMenuItem: string;
    setSelectedMenuItem: (name: string) => void;
  }
) => {

  if (!isOpen || selectedTopItem === null || selectedTopItem === undefined) {
    return <></>;
  }

  const selectedTab = selectedTopItem?.children[selectedTabIndex];
  const selectedTabChildren = selectedTab?.children;

  return (
    <>
      <ItemLabel>{selectedTopItem?.label}</ItemLabel>
      <ItemHeading>{selectedTopItem.name}</ItemHeading>
      <TabWrap>
        {selectedTopItem.children.map((child, i, a) => {
          const Text = selectedTabIndex === i ? TabTextSelected : TabText;
          return (
            <Text
              key={i}
              onClick={() => setSelectedTabIndex(i)}
            >
              {child.name}
            </Text>
          );
        })}
      </TabWrap>
      {selectedTabChildren && selectedTabChildren.map((child, i, a) => {
        const indexName = i + '-' + child.name;
        const Link = activeMenuItem === indexName ? HoverLinkActive : HoverLink;
        const Image = activeMenuItem === indexName ? HoverLinkImageActive : HoverLinkImage;
        return (
          <Link
            key={i + '-' + child.name}
            to={child.url}
            title={child.name}
            onClick={(e) =>
              onClickOrRedirect({
                onClick: () => setSelectedMenuItem(i + '-' + child.name),
                item: child,
                e: e,
              })()
            }
          >
            <MenuLinkText>{child.name}</MenuLinkText>
            {child.icon?.url && (
              <Image
                src={child.icon?.url}
                alt={''}
              />
            )}
          </Link>
        );
      }
      )}
      <AdditionalLinksWrap>
        {selectedTab && selectedTab.showAllText && selectedTopItem.url && (
          <HoverExtraLink
            to={selectedTab.url ?? selectedTopItem.url}
            title={selectedTab.showAllText}
            css={{
              color: style.colors.monochrome.black,
            }}
          >
            {selectedTab.showAllText}
            <HoverExtraLinkIcon />
          </HoverExtraLink>
        )}
        {selectedTab.extraLinks && selectedTab.extraLinks.length > 0 && selectedTab.extraLinks?.map((child, i, a) => (
          <ExtraLink
            key={i}
            to={child.url}
            title={child.name}
            css={{
              color: colorFromTag(child.tag) ?? style.colors.monochrome.black,
            }}
          >
            {child.name}
          </ExtraLink>
        ))}
      </AdditionalLinksWrap>
    </>
  );
};

const SecondaryHeadingWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: { bottom: '24px' },
})

const SecondaryHeading = styled.span({
  ...boldUcase,
  fontSize: '32px',
  lineHeight: '100%',
  textAlign: 'left',
  margin: { xy: 0 },
})

const SecondaryHeadingIcon = styled.img({
  height: 40,
  width: 40,
})

const SecondaryShowAllLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
  borderBottom: {
    width: '2px',
    style: 'solid',
    color: style.colors.monochrome.black
  },
  paddingBottom: '12px',
  marginBottom: '24px'
})

const SecondaryShowAllLinkIcon = styled(Right12x12, {
  marginLeft: '4px',
})

const SecondaryShowAllText = styled.span({
  ...boldUcase,
  fontSize: '14px',
  lineHeight: '100%',
})

const SecondaryLinkBase = styled(Link, {
  fontSize: '16px',
  display: 'block',
  textDecoration: 'none',
  color: style.colors.monochrome.black,
})

const SecondaryExtraLinksWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  marginBottom: '24px',
})

const SecondaryExtraLink = styled(SecondaryLinkBase, {
  ...boldUcase,
  lineHeight: '100%',
})

const SecondaryItemLink = styled(SecondaryLinkBase, {
  ...boldUcase,
  textTransform: 'none',
  lineHeight: '130%',
  textAlign: 'left',
  margin: { xy: 0, bottom: 12 },
})

export const CategoryItemsModalContent = (
  {
    selectedItem,
    isMobile,
  }: {
    selectedItem: MainMenuCategoryItem;
    isMobile: boolean;
  }
) => {
  if (selectedItem === null || selectedItem === undefined) {
    return <></>;
  }

  const modalContent = (<>
    <SecondaryHeadingWrap>
      <SecondaryHeading>{selectedItem.name}</SecondaryHeading>
      {selectedItem.icon?.url && (
        <SecondaryHeadingIcon
          src={selectedItem.icon?.url}
          alt={''}
        />
      )}
    </SecondaryHeadingWrap>
    {selectedItem.url && selectedItem.showAllText &&
      <SecondaryShowAllLink
        to={selectedItem.url}
        title={selectedItem.showAllText}
      >
        <SecondaryShowAllText>{selectedItem.showAllText}</SecondaryShowAllText>
        <SecondaryShowAllLinkIcon />
      </SecondaryShowAllLink>
    }
    {selectedItem.extraLinks && selectedItem.extraLinks.length > 0 && <SecondaryExtraLinksWrap>
      {selectedItem.extraLinks?.map((child, i, a) => (
        <SecondaryExtraLink
          key={i}
          to={child.url}
          title={child.name}
        >
          {child.name}
        </SecondaryExtraLink>
      ))}
    </SecondaryExtraLinksWrap>
    }
    {selectedItem.children.map((child, i) => (
      <SecondaryItemLink
        key={i}
        to={child.url}
        title={child.name}
      >
        {child.name}
      </SecondaryItemLink>
    ))}
  </>
  );

  if (isMobile) {
    return (
      modalContent
    )
  }

  return (
    <ModalScrollableContentSecondTierDesktop>
      {modalContent}
    </ModalScrollableContentSecondTierDesktop>
  );
}

const TieredModal = (props: PropType) => {
  const { mainMenu } = props;
  const isOpen = mainMenu.selectedCategory && props.mainMenu.isOpen;
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [activeMenuItem, setSelectedMenuItem] = useState('');

  const secondTier = mainMenu.selectedCategory?.children[selectedTabIndex]?.children.find(
    (child, i) => activeMenuItem === i + '-' + child.name,
  );

  useEffect(() => {
    setSelectedTabIndex(0);
  }, [mainMenu.selectedCategory]);
  useEffect(() => {
    setSelectedMenuItem('');
  }, [selectedTabIndex, mainMenu.selectedCategory, props.mainMenu.isOpen]);

  const primaryModalContent = CategoryModalContent({ isOpen, selectedTabIndex, selectedTopItem: mainMenu.selectedCategory, setSelectedTabIndex, activeMenuItem, setSelectedMenuItem })
  const secondaryModelContent = CategoryItemsModalContent({ selectedItem: secondTier, isMobile: false });

  return (
    <LeftTieredModalDesktop
      FirstTierChildren={primaryModalContent}
      SecondTierChildren={secondaryModelContent}
      isOpen={isOpen}
      onClose={() => props.setMainMenuCategory(null)}
      secondTier={!!secondTier}
    />
  );
};

const logout = async (path: string) => {
  try {
    const result = await post(path);

    if (result && result.status >= 200 && result.status < 400) {
      addUserLog('Logout successful');
      await pushState(startPage(), {
        includeAppShellData: true,
      });
      return Promise.resolve();
    }
    addUserLog('Logout failed');
  } catch (e) {
    addUserLog('Logout failed:' + e.stack);
  }
  return Promise.reject(null);
};

export function MenuLinkItemOnClick(item: MenuLinkItem | LinkItem, openAccountBox: () => void) {
  switch (item.actionType) {
    case 'modalLogin':
      return openAccountBox(); //Dispatch function for opening the login modal
    case 'link':
      return pushState(item.url);
    case 'post':
      return logout(item.url);
  }
}

export const DesktopTieredModal = connect(
  (state) => ({
    mainMenu: state.mainMenu,
  }),
  (dispatch) => ({
    toggleMainMenu: (item: MainMenuItemType) => {
      dispatch(toggleMainMenu(item));
    },
    setMainMenuCategory(item: MainMenuItemType) {
      dispatch(setMainMenuCategory(item));
    },
    openAccountBox(scene?: AccountBoxScene) {
      dispatch(openAccountBox(scene));
    },
  }),
)(TieredModal);

export const iconCodes = ['LOGOUT', 'USER', 'CUSTOMERSERVICE', 'FINDSTORE', 'STORE', 'WISHLIST', 'FLAG'] as const;

export type iconCodes = typeof iconCodes[number];

export const ActionIconFromIconCode = ({ iconCode }: { iconCode: iconCodes }) => {
  switch (iconCode) {
    case 'FLAG':
      return null;
    case 'LOGOUT':
      return <Logout12x12 />;
    case 'USER':
      return <User24x24 />;
    case 'CUSTOMERSERVICE':
      return <QuestionCircle24x24 />;
    case 'FINDSTORE':
      return <Location24x24 />;
    case 'STORE':
      return <Location24x24 />;
    case 'WISHLIST':
      return <Favourite24x24 />;
  }
  return assertUnreachable(iconCode);
};

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}

export const LeftTieredModalDesktop = (props: {
  isOpen: boolean;
  onClose: () => void;
  secondTier: boolean;
  FirstTierChildren: React.ReactNode;
  SecondTierChildren: React.ReactNode;
  ShownBelowFirstTier?: React.ReactNode;
  secondTierColor?: string;
  secondTierWide?: boolean;
}) => {
  const {
    isOpen,
    onClose,
    secondTier,
    FirstTierChildren,
    SecondTierChildren,
    ShownBelowFirstTier,
    secondTierColor = style.colors.monochrome.extraLightGrey,
    secondTierWide = false,
  } = props;

  return (
    <Modal
      modalIsShown={isOpen}
      hideModal={() => onClose()}
      paneContent={SecondTierChildren}
      paneContentShown={secondTier}
      paneBackgroundColor={secondTierColor}
      paneWidthInColumns={secondTierWide ? 6 : 3}
      isOnLeft
    >
      <ModalScrollableContent>{FirstTierChildren}</ModalScrollableContent>
      {ShownBelowFirstTier}
    </Modal>
  );
};
