import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { addUserLog, postJson, pushState, replaceState } from '@polarnopyret/scope';
import { ConnectPropType as FeedbackPropType, ButtonTheme } from 'Shared/Button/Feedback';
import { Checkbox } from 'Shared/Fields/Toggle';
import { SolidButton } from 'Shared/TextButtons';
import { ResponsiveSize, Text } from 'Shared/SharedComponents/atoms/Typography';
import Input from 'Shared/Fields/Text';
import Form from 'Shared/Fields/Form';
import { loginUrl, myAccountPage } from 'Shared/known-urls';
import LoginModel from 'Account/Login/LoginModel.type';
import LoginViewModel from 'Account/Login/LoginViewModel.type';
import { ModalScrollableContent, ModalTopContent } from 'Shared/SharedComponents/atoms/Modal';
import Html from 'Shared/Partials/PropertyTypes/Html';
import LoginResultViewModel from 'Account/Login/LoginResultViewModel.type';
import { BorderStyle } from 'Shared/Style';
import * as style from 'Shared/Style';

type RequiredPropType = {
  redirectOnLogin: boolean;
  redirectToAfterLogin: string;
  toForgotPassword: () => void;
  toRegister: () => void;
  isCompact: boolean;
  hideModal: () => void;
};

type PropType = RequiredPropType & FeedbackPropType;

const ModalScrollableContentWrap = styled(ModalScrollableContent, {
  paddingTop: '12px',
  ['@media ' + style.mediaMinLarge]: {
    paddingTop: '0px',
  },
})

const ErrorText = styled(Text, {
  color: style.colors.negativeColors.negative, 
  paddingBottom: '7px',
})

const InputEmail = styled(Input, {
  display: 'block', 
  marginBottom: '8px'
})

const InputPassword = styled(Input, {
  display: 'block', 
  marginBottom: '12px'
})

const ForgotPasswordText = styled(Text, {
  cursor: 'pointer',
  color: style.colors.monochrome.black,
  textDecorationLine: 'underline',
  width: '100%',
  marginBottom: '12px',
})

const ButtonWrap = styled.div({
  width: '100%',
  marginTop: '24px',
  columnGap: '12px',
  display: 'flex',
  flexDirection: 'row',
  ['@media ' + style.mediaMinLarge]: {
    marginTop: '32px',
    columnGap: '16px',
  },
})

const HtmlWrap = styled.div({
  marginTop: '24px',
  ['@media ' + style.mediaMinLarge]: {
    marginTop: '32px',
  },
})

const LoginForm = (props: PropType & { content: LoginViewModel; email: string; setEmail: (email: string) => void }) => {
  const { content, email, setEmail, isCompact, feedback, hideModal } = props;
  const FeedbackButton = feedback.Button;

  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  async function postLogin() {
    try {
      const result: LoginResultViewModel = await postJson(loginUrl(), {
        email: email.trim(),
        password: password.trim(),
        rememberMe,
      } as LoginModel);
      if (result.success) {
        addUserLog('Login successful');
      } else {
        addUserLog('Login failed');
        setErrorMessage(result.message);
        return Promise.reject(null);
      }
    } catch (e) {
      setErrorMessage(e.errorMessage);
      return Promise.reject(null);
    }

    if (props.redirectOnLogin) {
      pushState(props.redirectToAfterLogin || myAccountPage(), {
        includeAppShellData: true,
        hideSpinner: true,
      });
    } else {
      pushState(null, {
        includeAppShellData: true,
        hideSpinner: true,
      });
    }

    return Promise.resolve();
  }

  const formContent = (
    <>
      {!!errorMessage && (
        <ErrorText fontSize={ResponsiveSize.D14_M14_L130}>
          {errorMessage}
        </ErrorText>
      )}
      <Form onSubmitCallback={() => feedback.push(postLogin())} isIncomplete={!email || !password}>
        <InputEmail
          value={email}
          required
          type="email"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
          placeholder={content?.labels.email}
          errorText={email ? content?.labels.invalidEmail : content?.labels.missingEmail}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <InputPassword
          value={password}
          required
          type="password"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}
          placeholder={content?.labels.password}
          errorText={password ? content?.labels.invalidPassword : content?.labels.missingPassword}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <ForgotPasswordText
          fontSize={ResponsiveSize.D14_M14_L130}
          onClick={() => props.toForgotPassword()}
        >
          {content?.labels.forgotPassword}
        </ForgotPasswordText>
        <Checkbox
          checked={rememberMe}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setRememberMe(e.currentTarget.checked)}
        >
          {content?.labels.rememberMe}
        </Checkbox>
        <ButtonWrap>
          <FeedbackButton
            type="submit"
            fullWidth
            display="block"
            pendingText={content?.labels.login}
            text={content?.labels.login}
            theme={ButtonTheme.BlackToWhite}
            onClick={() => {}}
          />
          <SolidButton
            fullWidth
            display="block"
            text={content?.labels.becomeMember}
            theme={ButtonTheme.WhiteToBlack}
            onClick={() => props.toRegister()}
          />
        </ButtonWrap>
      </Form>
      <HtmlWrap>
        <Html html={content?.memberUspModalBody?.html} />
      </HtmlWrap>
    </>
  );

  return (
    <ModalScrollableContentWrap>
      <ModalTopContent
        textAboveHeading={content?.label}
        closeModal={hideModal}
        heading={content?.heading}
        bottomLine={false}
        bottomPadding={32}
      ></ModalTopContent>
      {formContent}
    </ModalScrollableContentWrap>
  );

  /* return isCompact ? (
    <ModalScrollableContentWrap>
      <ModalTopContent
        textAboveHeading={content?.label}
        closeModal={hideModal}
        heading={content?.heading}
        bottomLine={false}
        bottomPadding={32}
      ></ModalTopContent>
      {formContent}
    </ModalScrollableContentWrap>
  ) : (
    <ModalScrollableContentWrap>
      <ModalTopContent closeModal={hideModal} textAboveHeading={content?.label} heading={content?.heading}></ModalTopContent>
      {formContent}
    </ModalScrollableContentWrap>
  ); */
};

export default LoginForm;
