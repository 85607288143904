import { Action, UIInitialState, UIType } from 'Shared/State';
import { addUserLog } from '@polarnopyret/scope';
import ProductListItemViewModel from 'Product/ProductListing/ProductListItemViewModel.type';
import StoreModel from 'Stores/StoreModel.type';
import MiniCartViewModel from 'Cart/Models/MiniCartViewModel.type';

export default function (state: UIType, action: Action): UIType {
  state = state || {
    ...UIInitialState,
  };

  switch (action.type) {
    case SHOW_CART_MODAL: {
      return {
        ...state,
        addedToCartModal: null,
        displayCartModal: true,
      };
    }
    case HIDE_CART_MODAL: {
      return {
        ...state,
        displayCartModal: false,
      };
    }    
    case SET_DISPLAYED_SHOP_THE_LOOK_PRODUCTS: {
      return {
        ...state,
        displayedQuickShopProducts: (action as SetDisplayedQuickShopProductsAction).displayedQuickShopProducts,
      };
    }
    case SET_SELECTED_STORE: {
      return {
        ...state,
        selectedStore: (action as SetSelectedStoreAction).selectedStore,
      };
    }
    case SET_ADDED_TO_CART_MODAL: {
      return {
        ...state,
        addedToCartModal: (!state.displayCartModal ? {
          code: (action as SetAddedToCartModalAction).code,
          message: (action as SetAddedToCartModalAction).message,
          miniCart: (action as SetAddedToCartModalAction).miniCart,
        } : null),
      };
    }
    case CLEAR_ADDED_TO_CART_MODAL: {
      return {
        ...state,
        addedToCartModal: null,
      };
    }
    case SET_HEADER_HEIGHT: {
      return {
        ...state,
        headerHeight: (action as SetHeaderHeightAction).headerHeight,
      };
    }
    case SET_MODAL_IS_OPEN: {
      return {
        ...state,
        modalIsOpen: (action as SetModalIsOpenAction).isOpen,
      };
    }
    default:
      return state;
  }
}

//Actions

export type ShowCartModalAction = Action & {};
export type HideCartModalAction = Action & {};

export type SetDisplayedQuickShopProductsAction = Action & {
  displayedQuickShopProducts?: ProductListItemViewModel[];
};

export type SetSelectedStoreAction = Action & {
  selectedStore?: StoreModel | null;
};

export type SetAddedToCartModalAction = Action & {
  //When set, triggers the display of the added-to-cart modal
  code: string;
  message: string;
  miniCart: MiniCartViewModel;
};

export type ClearAddedToCartModalAction = Action & {};

export type SetHeaderHeightAction = Action & { headerHeight: number };

export type SetModalIsOpenAction = Action & { isOpen: boolean };

export function SetAddedToCartModal(
  code: string,
  message: string,
  miniCart: MiniCartViewModel,
): SetAddedToCartModalAction {
  return {
    type: SET_ADDED_TO_CART_MODAL,
    code,
    message,
    miniCart,
  };
}

export function ClearAddedToCartModal(): ClearAddedToCartModalAction {
  return {
    type: CLEAR_ADDED_TO_CART_MODAL,
  };
}

export function ShowCartModal() {
  addUserLog('Show cart content modal');
  return {
    type: SHOW_CART_MODAL,
  } as ShowCartModalAction;
}

export function HideCartModal() {
  addUserLog('Hide cart content modal');
  return {
    type: HIDE_CART_MODAL,
  } as HideCartModalAction;
}


export function SetDisplayedQuickShopProducts(displayedQuickShopProducts?: ProductListItemViewModel[]) {
  addUserLog('Set displayed quick shop products');
  return {
    type: SET_DISPLAYED_SHOP_THE_LOOK_PRODUCTS,
    displayedQuickShopProducts,
  } as SetDisplayedQuickShopProductsAction;
}

export function SetSelectedStore(selectedStore?: StoreModel | null) {
  addUserLog('Set selected store');
  return {
    type: SET_SELECTED_STORE,
    selectedStore,
  } as SetSelectedStoreAction;
}

export function SetHeaderHeight(headerHeight: number) {
  addUserLog('Set height of the header as it is calculated');
  return {
    type: SET_HEADER_HEIGHT,
    headerHeight,
  } as SetHeaderHeightAction;
}

export function SetModalIsOpen(isOpen: boolean) {
  return {
    type: SET_MODAL_IS_OPEN,
    isOpen,
  } as SetModalIsOpenAction;
}

export const SET_DISPLAYED_SHOP_THE_LOOK_PRODUCTS = 'SET_DISPLAYED_SHOP_THE_LOOK_PRODUCTS';
export const SET_SELECTED_STORE = 'SET_SELECTED_STORE';
export const SET_ADDED_TO_CART_MODAL = 'SET_ADDED_TO_CART_MODAL';
export const CLEAR_ADDED_TO_CART_MODAL = 'CLEAR_ADDED_TO_CART_MODAL';
export const SET_HEADER_HEIGHT = 'SET_HEADER_HEIGHT';
export const SHOW_CART_MODAL = 'SHOW_CART_MODAL';
export const SET_MODAL_IS_OPEN = 'SET_MODAL_IS_OPEN';
export const HIDE_CART_MODAL = 'HIDE_CART_MODAL';
