import { addUserLog, URLX, get, pathCombine } from '@polarnopyret/scope';
import { searchPageUrl } from 'Shared/known-urls';
import { Dispatch, Action } from 'Shared/State';
import QuickSearchViewModelType from './QuicksearchViewModel.type';
import EmptySearchViewModelType from './EmptySearchViewModel.type';
export const SHOW_QUICKSEARCH = 'SHOW_QUICKSEARCH';
export const HIDE_QUICKSEARCH = 'HIDE_QUICKSEARCH';
export const UPDATE_QUICKSEARCH_TEXT = 'UPDATE_QUICKSEARCH_TEXT';
export const UPDATE_QUICKSEARCH_COMPLETIONS = 'UPDATE_QUICKSEARCH_COMPLETIONS';
export const UPDATE_EMPTYSEARCH = 'UPDATE_EMPTYSEARCH';
export const UPDATE_SELECTED_QUICKSEARCH_COMPLETION = 'UPDATE_SELECTED_QUICKSEARCH_COMPLETION';
export const QICKSEARCH_COMPLETIONS_ID = 'completions';
export const QICKSEARCH_PRODUCTS_ID = 'products';
export const QICKSEARCH_CATEGORIES_ID = 'categories';
export const QICKSEARCH_CMSPAGES_ID = 'cmspages';
export const QUICKSEARCH_BRANDS_ID = 'brands';
export const QICKSEARCH_STORES_ID = 'stores';
export const SEARCH_QUERY_NAME = 'q';
export const SEARCH_PAGE_LOAD_QUERY_NAME = 'preview';

export const MIN_SEARCH_LENGTH = 2;

export const DELAY_SEARCH_IN_MS = 500;

export type UpdateQuicksearchTextAction = Action & {
  searchText: string;
};

export type HideQuicksearchAction = Action & {
  setFirstSuggestionAsSearchText: boolean;
};

export type UpdateSelectedQuicksearchCompletionAction = Action & {
  index: number;
};

export type UpdateQuicksearchCompletionsAction = Action & {
  quicksearchLists: QuickSearchViewModelType;
};

export type UpdateEmptySearchCompletionsAction = Action & {
  emptySearchLists: EmptySearchViewModelType;
};

const autocompletionCount = 3;
let lastRequestId = 0;

export function hideQuickSearch(setFirstSuggestionAsSearchText = true): HideQuicksearchAction {
  addUserLog('Hiding quick search');
  return {
    type: HIDE_QUICKSEARCH,
    setFirstSuggestionAsSearchText,
  };
}

export function showQuickSearch() {
  addUserLog('Showing quick search');
  return {
    type: SHOW_QUICKSEARCH,
  } as Action;
}

export function updateSelectedCompletion(index: number) {
  return {
    type: UPDATE_SELECTED_QUICKSEARCH_COMPLETION,
    index,
  } as UpdateSelectedQuicksearchCompletionAction;
}

export function loadQuickSearch(searchText: string) {
  addUserLog('Loading quick search for: ' + searchText);
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_QUICKSEARCH_TEXT,
      searchText,
    } as UpdateQuicksearchTextAction);

    const requestId = (lastRequestId = Math.random());
    if (searchText.length > MIN_SEARCH_LENGTH) {
      const url = new URLX(pathCombine(searchPageUrl(), 'quicksearch'));
      url.searchParams.set(SEARCH_QUERY_NAME, searchText);
      url.searchParams.set('count', String(autocompletionCount));
      return get(url)
        .then(r => r.json())
        .then((json: QuickSearchViewModelType) => {
          if (requestId === lastRequestId) {
            dispatch({
              type: UPDATE_QUICKSEARCH_COMPLETIONS,
              quicksearchLists: json,
            } as UpdateQuicksearchCompletionsAction);
          }
        });
    }
  };
}

export function loadEmptySearch(executeSearch: boolean) {
  return (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_EMPTYSEARCH,
      emptySearchLists: null,
    } as UpdateEmptySearchCompletionsAction);

    const requestId = (lastRequestId = Math.random());
    if(executeSearch) {
      // Create an empty search to get suggestions from Esales
      const url = new URLX(pathCombine(searchPageUrl(), 'initialsearch'));
      get(url)
        .then(r => r.json())
        .then((json: EmptySearchViewModelType) => {
          if (requestId === lastRequestId) {
            dispatch({
              type: UPDATE_EMPTYSEARCH,
              emptySearchLists: json,
            } as UpdateEmptySearchCompletionsAction);
          }
        });
    }
  };
}

export function searchTextToWords(searchText: string) {
  return searchText.replace(/\s+/, ' ').split(' ');
}
