import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { addUserLog, pathCombine, postJson, pushState, replaceState } from '@polarnopyret/scope';
import { ConnectPropType as FeedbackPropType, ButtonTheme } from 'Shared/Button/Feedback';
import { Checkbox } from 'Shared/Fields/Toggle';
import { ResponsiveSize, Text } from 'Shared/SharedComponents/atoms/Typography';
import Input from 'Shared/Fields/Text';
import Form from 'Shared/Fields/Form';
import { myAccountPage, resetPasswordPage } from 'Shared/known-urls';
import { ModalScrollableContent, ModalTopContent } from 'Shared/SharedComponents/atoms/Modal';
import { BorderStyle } from 'Shared/Style';
import ResetPasswordPageViewModel from 'ResetPassword/ResetPasswordPageViewModel.type';
import ResetPasswordResultViewModel from 'ResetPassword/ResetPasswordResultViewModel.type';
import * as style from 'Shared/Style';

type RequiredPropType = {
  redirectOnLogin: boolean;
  redirectToAfterLogin: string;
  toForgotPassword: () => void;
  isCompact: boolean;
};

type PropType = RequiredPropType & FeedbackPropType;

const ModalScrollableContentContainer = styled(ModalScrollableContent, {
  paddingTop: '12px',
})

const ModalScrollableContentContainerDesktop = styled(ModalScrollableContent, {
  paddingTop: '8px',
})

const InputElem = styled(Input, {
  display: 'block', 
  marginBottom: '16px',
  ['@media ' + style.mediaMinLarge]: {
    marginBottom: '24px',
  },
})

const IntroText = styled(Text, {
  color: style.colors.monochrome.black,
  width: '100%',
  marginBottom: '32px',
})

const ErrorText = styled(Text, {
  color: style.colors.negativeColors.negative, 
  paddingBottom: '7px'
})

const ButtonWrap = styled.div({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  marginTop: '24px',
  columnGap: '12px',

  ['@media ' + style.mediaMinLarge]: {
    marginTop: '32px',
    columnGap: '16px',
  },
})

const ResetPasswordForm = (props: PropType & { content: ResetPasswordPageViewModel; email: string; token: string }) => {
  const { content, email, token, toForgotPassword, isCompact, feedback } = props;
  if (!email || !token || !content.isTokenValid) {
    toForgotPassword();
  }
  const FeedbackButton = feedback.Button;

  const [newPassword, setNewPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function postUpdatePassword() {
    try {
      const result: ResetPasswordResultViewModel = await postJson(pathCombine(resetPasswordPage(), 'UpdatePassword'), {
        email: email,
        token: token,
        password: newPassword.trim(),
      });
      if (result.success) {
        addUserLog('Update password successful');
      } else {
        addUserLog('Update password failed');
        setErrorMessage(result.message);
        return Promise.reject(null);
      }
    } catch (e) {
      setErrorMessage(e.errorMessage);
      return Promise.reject(null);
    }

    if (props.redirectOnLogin) {
      pushState(props.redirectToAfterLogin || myAccountPage(), {
        includeAppShellData: true,
        hideSpinner: true,
      });
    } else {
      replaceState(null, {
        includeAppShellData: true,
        hideSpinner: true,
      });
    }

    return Promise.resolve();
  }

  const formContent = (
    <>
      <IntroText
        fontSize={ResponsiveSize.D16_M16}
        css={{
          ...(errorMessage && {
            marginBottom: '4px',
          })
        }}
      >
        {content?.createPasswordIntro}
      </IntroText>
      {!!errorMessage && (
        <ErrorText fontSize={ResponsiveSize.D14_M14_L130}>
          {errorMessage}
        </ErrorText>
      )}
      <Form onSubmitCallback={() => feedback.push(postUpdatePassword())} isIncomplete={!newPassword}>
        <InputElem
          value={newPassword}
          required
          type={showPassword ? 'text' : 'password'}
          minLength={6}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setNewPassword(e.currentTarget.value)}
          placeholder={content?.labels.enterNewPassword}
          errorText={newPassword ? content?.labels.invalidPassword : content?.labels.missingPassword}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <Checkbox
          checked={showPassword}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setShowPassword(e.currentTarget.checked)}
        >
          {content?.labels.showPassword}
        </Checkbox>
        <ButtonWrap>
          <FeedbackButton
            type="submit"
            fullWidth
            display="block"
            pendingText={content?.labels.changePassword}
            text={content?.labels.changePassword}
            theme={ButtonTheme.BlackToWhite}
            onClick={() => {}}
          />
        </ButtonWrap>
      </Form>
    </>
  );
        
  const ModalScrollable = isCompact ? ModalScrollableContentContainer : ModalScrollableContentContainerDesktop;
  return (
    <ModalScrollable>
      <ModalTopContent heading={content?.createPasswordHeading} bottomLine={false} bottomPadding={16}></ModalTopContent>
      {formContent}
    </ModalScrollable>
  );
};

export default ResetPasswordForm;
