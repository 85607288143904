import { getLocalStorageItem, setLocalStorageItem } from '@polarnopyret/scope';
import OrdererViewModelType from './Order/OrdererViewModel.type';

const customerInfoKey = 'customerinfo';
const paymentMethodKey = 'paymentmethod';
const shippingMethodKey = 'shippingmethod';

export function save(orderer: OrdererViewModelType) {
  setLocalStorageItem(customerInfoKey, orderer);
}

export function get() {
  const savedOrderer = getLocalStorageItem<OrdererViewModelType>(customerInfoKey);
  if (!savedOrderer) {
    return null;
  }

  const empty: OrdererViewModelType = {
    billingAddress: null,
    alternativeDeliveryAddress: null,
    email: null,
    phoneNumber: null,
    hasAlternativeDeliveryAddress: false,
    hasEnoughInfoToCompletePurchase: false,
    emailHashed: null,
  };

  return Object.assign(empty, savedOrderer);
}

export function saveShippingMethodId(id: string) {
  setLocalStorageItem(shippingMethodKey, id);
}

export function getShippingMethodId() {
  return getLocalStorageItem<string>(shippingMethodKey);
}

export function savePaymentMethodId(id: string) {
  setLocalStorageItem(paymentMethodKey, id);
}

export function getPaymentMethodId() {
  return getLocalStorageItem<string>(paymentMethodKey);
}
