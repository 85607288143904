import { Style } from '@glitz/type';

export function mergeShallow(...styles: Style[]) {
  return styles.reduce((total, style) => {
    if (style) {
      for (const prop in style) {
        const val = style[prop];
        if (typeof val === 'object') {
          total[prop] = total[prop] || {};
          // tslint:disable-next-line:forin
          for (const inner in val) {
            total[prop][inner] = val[inner];
          }
        } else {
          total[prop] = val;
        }
      }
    }
    return total;
  }, {});
}
