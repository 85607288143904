import { isIE } from '@polarnopyret/scope';

export function postForm(url: string, postData: { [item: string]: string }, encoding: string) {
  const form = document.createElement('form') as HTMLFormElement;
  if (isIE()) {
    // This is crazy, but yeah...
    // http://blog.higher-order.net/2008/12/20/ie-doesnt-understand-html-or-http.html
    (document as any).charset = encoding;
  }
  form.acceptCharset = encoding;
  form.style.display = 'none';
  form.method = 'post';
  form.action = url;

  Object.keys(postData).forEach(key => {
    const input = document.createElement('input') as HTMLInputElement;
    input.type = 'hidden';
    input.value = postData[key];
    input.name = key;
    form.appendChild(input);
  });

  document.body.appendChild(form);
  form.submit();
}
