export default function deepFreeze<TObject extends object>(o: TObject): Readonly<TObject> {
  if (process.env.NODE_ENV !== 'production') {
    if (Array.isArray(o)) {
      o.forEach(i => deepFreeze(o[i]));
    } else if (typeof o === 'object' && o !== null) {
      Object.keys(o).forEach(key => deepFreeze(o[key]));
    }
    Object.freeze(o);
  }
  return o;
}
