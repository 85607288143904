/**
 * @ComponentFor EPiServer.Core.XhtmlString
 */
import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { Html, HtmlProps, registerDecorator } from '@polarnopyret/scope-episerver';
import Image from '../../Image';
import { Size } from '../../image-sizes';
import { TR, TD, TH, Table } from 'Shared/Partials/PropertyTypes/Table/pop-context';
import A from 'Shared/Generic/A';
import { LinkButton, ButtonTheme } from 'Shared/TextButtons'
import * as style from 'Shared/Style';

type TableStyle = {
  TR: React.ComponentClass<any> | React.StatelessComponent<any>;
  TD: React.ComponentClass<any> | React.StatelessComponent<any>;
  TH: React.ComponentClass<any> | React.StatelessComponent<any>;
  Table: React.ComponentClass<any> | React.StatelessComponent<any>;
};

registerDecorator('img', (item, i, props, componentProps) => {
  let size = Size.Large;
  if (props['data-size']) {
    size = props['data-size'];
  }
  if (componentProps.keepImageSizes !== true) {
    if (props.width) {
      delete props.width;
    }
    if (props.height) {
      delete props.height;
    }
  }
  return <Image {...props} preset={size} />;
});

const decoratorFactory = (Component: React.ReactType, mapProps?: (props: any) => any) => {
  const C = (item: any, i: number, props: any, componentProps: any, children: React.ReactNode | React.ReactNode[]) => {
    return <Component {...(mapProps ? mapProps(props) : props)}>{children}</Component>;
  };
  return C;
};

registerDecorator(
  'a', (item, i, props, componentProps, children) => {
    const isBtn = props.className && props.className.includes('btn')
    if(isBtn) {
      return (
        <LinkButton key={i} theme={ButtonTheme.WhiteToBlack} title={props.title} to={props.href}>{children}</LinkButton>
      );
    }
    return <A to={props.href} title={props.title} {...props} useDefaultLinkColor={true}>{children}</A>
  }
);

const getLastClass = (props: any) => (props.className || '').split(' ').reverse()[0] || ''; // take last class only

registerDecorator('h1', (item, i, props, componentProps, children) => {
  return (
    <styled.H1 {...props}>
      {children}
    </styled.H1>
  );
});

registerDecorator('h2', (item, i, props, componentProps, children) => {
  return (
    <styled.H2 {...props}>
      {children}
    </styled.H2>
  );
});

registerDecorator('h3', (item, i, props, componentProps, children) => {
  return (
    <styled.H3 {...props}>
      {children}
    </styled.H3>
  );
});

registerDecorator('h4', (item, i, props, componentProps, children) => {
  return (
    <styled.H4 {...props}>
      {children}
    </styled.H4>
  );
});

registerDecorator('h5', (item, i, props, componentProps, children) => {
  return (
    <styled.H5 {...props}>
      {children}
    </styled.H5>
  );
});

registerDecorator('h6', (item, i, props, componentProps, children) => {
  return (
    <styled.H6 {...props}>
      {children}
    </styled.H6>
  );
});

registerDecorator('p', (item, i, props, componentProps, children) => {
  return (
    <styled.P {...props}>
      {children}
    </styled.P>
  );
});

registerDecorator('q', (item, i, props, componentProps, children) => {
  return (
    <styled.Q {...props} spacing>
      {children}
    </styled.Q>
  );
});

type PropType = HtmlProps;

const cleanTableProps = (props: any) => {
  if (props.style) {
    if(props.style.height) {
      delete props.style.height;
    }

    if(props.style.width && !props.style.width.includes('%')) {
      delete props.style.width;
    }
  }

  if(props.border) {
    delete props.border;
  }

  if(props.width && !props.width.includes('%')) {
    delete props.width;
  }

  return props;
}

const cleanChildren = (children: React.ReactNode | React.ReactNode[]) => {
  const asArray = children as React.ReactNode[];
  if(asArray) {
    return asArray.filter(x => x.toString() !== '\n');
  }

  return children;
}

const TableWrap = styled.div({
  overflowX: 'scroll',  
  ['@media ' + style.mediaUpToLarge]: {
    // @ts-ignore
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },

  ['@media ' + style.mediaMinLarge]: {
    paddingBottom: '5px',
    // @ts-ignore
    '::-webkit-scrollbar-thumb': {
      backgroundColor: style.colors.monochrome.a11ygrey,
      borderRadius: '5px',
    },
    '::-webkit-scrollbar': {
      height: '10px',
    },
  },

})

export default styled(function EditorItems(props: PropType & StyledProps) {
  const { compose, ...restProps } = props;
  const tableStyle: TableStyle = {
    TR,
    TD,
    TH,
    Table,
  };

  registerDecorator('tr', (item, i, props, componentProps, children) => {
    return <tableStyle.TR key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.TR>;
  });
  registerDecorator('th', (item, i, props, componentProps, children) => {
    return <tableStyle.TH key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.TH>;
  });
  registerDecorator('td', (item, i, props, componentProps, children) => {
    return <tableStyle.TD key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.TD>;
  });
  registerDecorator('tbody', (item, i, props, componentProps, children) => {
    return <tbody key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tbody>;
  });
  registerDecorator('thead', (item, i, props, componentProps, children) => {
    return <thead key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</thead>;
  });
  registerDecorator('table', (item, i, props, componentProps, children) => {
    console.log(children);
    return (<TableWrap key={i} className='table'><tableStyle.Table {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.Table></TableWrap>);
  });
  registerDecorator('ul', (item, i, props, componentProps, children) => {
    return <div key={i} className='list'><ul {...cleanTableProps(props)}>{cleanChildren(children)}</ul></div>;
  });
  registerDecorator('ol', (item, i, props, componentProps, children) => {
    return <div key={i} className='list'><ol {...cleanTableProps(props)}>{cleanChildren(children)}</ol></div>;
  });
  return <Html className='editorial-box' {...restProps} />;
}
  // withTheme<PropType & StyledProps>(({ theme, setTheme, themeName, compose, ...restProps }) => {
    
  //   registerDecorator('tr', (item, i, props, componentProps, children) => {
  //     return <tableStyle.TR key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.TR>;
  //   });
  //   registerDecorator('th', (item, i, props, componentProps, children) => {
  //     return <tableStyle.TH key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.TH>;
  //   });
  //   registerDecorator('td', (item, i, props, componentProps, children) => {
  //     return <tableStyle.TD key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.TD>;
  //   });
  //   registerDecorator('tbody', (item, i, props, componentProps, children) => {
  //     return <tbody key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</tbody>;
  //   });
  //   registerDecorator('thead', (item, i, props, componentProps, children) => {
  //     return <thead key={i} {...cleanTableProps(props)}>{cleanChildren(children)}</thead>;
  //   });
  //   registerDecorator('table', (item, i, props, componentProps, children) => {
  //     console.log(children);
  //     return (<TableWrap key={i} className='table'><tableStyle.Table {...cleanTableProps(props)}>{cleanChildren(children)}</tableStyle.Table></TableWrap>);
  //   });
  //   registerDecorator('ul', (item, i, props, componentProps, children) => {
  //     return <div key={i} className='list'><ul {...cleanTableProps(props)}>{cleanChildren(children)}</ul></div>;
  //   });
  //   registerDecorator('ol', (item, i, props, componentProps, children) => {
  //     return <div key={i} className='list'><ol {...cleanTableProps(props)}>{cleanChildren(children)}</ol></div>;
  //   });
  //   return <Html className='editorial-box' {...restProps} />;
  // }),
);
