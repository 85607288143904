import { addUserLog } from '@polarnopyret/scope';
import { Action } from 'Shared/State';

export const HIDE_HEADER = 'HIDE_HEADER';
export const COMPACT_HEADER = 'COMPACT_HEADER';
export const SCROLLUP_HEADER = 'SCROLLUP_HEADER';

export const showScrollUpHeader = () => {
  addUserLog('Show the header when scrolled up');
  return {
    type: SCROLLUP_HEADER,
  } as Action;
};

export const showScrollDownHeader = (isMobile: boolean) => {
  addUserLog('Show the header when scrolled down');
  return isMobile
    ? ({
        type: HIDE_HEADER,
      } as Action)
    : ({
        type: COMPACT_HEADER,
      } as Action);
};
