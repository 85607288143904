import React, { useState } from 'react';
import { styled } from '@glitz/react';
import { addUserLog, postJson, pushState, replaceState, scrollTo } from '@polarnopyret/scope';
import { ConnectPropType as FeedbackPropType, ButtonTheme } from 'Shared/Button/Feedback';
import { Checkbox } from 'Shared/Fields/Toggle';
import { H2, ResponsiveSize, Text } from 'Shared/SharedComponents/atoms/Typography';
import Input from 'Shared/Fields/Text';
import Form from 'Shared/Fields/Form';
import { myAccountPage, registerPage } from 'Shared/known-urls';
import Html from 'Shared/Partials/PropertyTypes/Html';
import { BorderStyle } from 'Shared/Style';
import RegisterUserModel from 'Account/Register/RegisterUserModel.type';
import RegisterViewModel from 'Account/Register/RegisterViewModel.type';
import { Left16x16, Left24x24 } from 'Shared/Icons/Arrow';
import RegisterValidationResult from 'Account/Register/RegisterValidationResult.type';
import { getCookie } from 'Shared/Helper/cookieHelper';
import { ModalScrollableContent } from 'Shared/SharedComponents/atoms/Modal';
import * as style from 'Shared/Style';

const COOKIE_POP_COUNTRY_CODE = 'pop.country-code';

type RequiredPropType = {
  redirectOnLogin: boolean;
  redirectToAfterLogin: string;
  toLogin: () => void;
  isCompact: boolean;
  scrollElementRef?: React.MutableRefObject<HTMLDivElement>;
};

type PropType = RequiredPropType & FeedbackPropType;

const ScrollableWrap = styled(ModalScrollableContent, {
  paddingTop: '32px',
  ['@media ' + style.mediaMinLarge]: {
    paddingTop: '24px',
  },
})

const ErrorText = styled(Text, {
  color: style.colors.negativeColors.negative, paddingBottom: '7px'
})

const InputSsn = styled(Input, {
  display: 'block', marginBottom: '12px'
})

const InputEmail = styled(Input, {
  display: 'block', marginBottom: '8px'
})

const InputFirstName = styled(Input, {
  display: 'block', marginBottom: '12px'
})

const InputLastName = styled(Input, {
  display: 'block', marginBottom: '12px'
})

const InputMobile = styled(Input, {
  display: 'block', 
  marginBottom: '16px',
  ['@media ' + style.mediaMinLarge]: {
    marginBottom: '24px',
  },
})

const ButtonWrap = styled.div({
  width: '100%',
  marginTop: '16px',
  display: 'flex',
  flexDirection: 'row',
  columnGap: '12px',

  ['@media ' + style.mediaMinLarge]: {
    marginTop: '24px',
    columnGap: '16px',
  },
})

const BackToLoginTextWrap = styled.div({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  color: style.colors.monochrome.black,
  paddingBottom: '12px',
  borderBottom: { style: 'solid', width: '2px', color: style.colors.monochrome.black },
})

const BackToLoginText = styled(Text, {
  width: '100%',
})

const Heading = styled(H2, {
  paddingBottom: '16px',
})

const RegisterForm = (
  props: PropType & { content: RegisterViewModel; email: string; setEmail: (email: string) => void },
) => {
  const { content, email, setEmail, isCompact, feedback } = props;
  const FeedbackButton = feedback.Button;
  const [personNumber, setPersonNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  async function postRegister() {
    try {
      const result: RegisterValidationResult = await postJson(registerPage(), {
        firstName: firstName.trim(),
        lastName: lastName.trim(),
        email: email.trim(),
        cellPhone: mobileNumber.trim(),
        socialSecurityNumber: personNumber.trim(),
        countryCode: getCookie(COOKIE_POP_COUNTRY_CODE),
        updateAction: '',
      } as RegisterUserModel);
      if (result.isValid) {
        addUserLog('Registration successful');
        props.toLogin(); // Reset view to login
      } else {
        addUserLog('Registration failed');
        setErrorMessage(result.message);
        if (props.scrollElementRef) {
          props.scrollElementRef.current.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
        }
        return Promise.reject(null);
      }
    } catch (e) {
      setErrorMessage(e.errorMessage);
      if (props.scrollElementRef) {
        props.scrollElementRef.current.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
      return Promise.reject(null);
    }

    if (props.redirectOnLogin) {
      pushState(props.redirectToAfterLogin || myAccountPage(), {
        includeAppShellData: true,
        hideSpinner: true,
      });
    } else {
      replaceState(null, {
        includeAppShellData: true,
        hideSpinner: true,
      });
    }

    return Promise.resolve();
  }

  const formContent = (
    <>
      <styled.Div css={{ marginBottom: errorMessage ? '4px' : '32px' }}>
        <Html html={content?.memberUspModalBody?.html} />
      </styled.Div>
      {!!errorMessage && (
        <ErrorText fontSize={ResponsiveSize.D14_M14_L130}>
          {errorMessage}
        </ErrorText>
      )}
      <Form
        onSubmitCallback={() => feedback.push(postRegister())}
        isIncomplete={!personNumber || !email || !firstName || !lastName || !mobileNumber || !termsAccepted}
      >
        {props.content.enabledSocialSecurityNumber && 
          <InputSsn
            value={personNumber}
            required
            type="text"
            onChange={(e: React.FormEvent<HTMLInputElement>) => setPersonNumber(e.currentTarget.value)}
            placeholder={content?.labels.socialSecurityNr}
            pattern={content?.regex.socialSecurity}
            errorText={personNumber ? content?.labels.invalidSocialSecurityNr : content?.labels.missingSocialSecurityNr}
            borderStyle={BorderStyle.BlackThinSolid}
          />
        }
        <InputEmail
          value={email}
          required
          type="email"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}
          placeholder={content?.labels.email}
          pattern={content?.regex.email}
          errorText={email ? content?.labels.invalidEmail : content?.labels.missingEmail}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <InputFirstName
          value={firstName}
          required
          type="text"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setFirstName(e.currentTarget.value)}
          placeholder={content?.labels.firstName}
          errorText={firstName ? content?.labels.invalidFirstName : content?.labels.missingFirstName}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <InputLastName
          value={lastName}
          required
          type="text"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setLastName(e.currentTarget.value)}
          placeholder={content?.labels.lastName}
          errorText={lastName ? content?.labels.invalidLastName : content?.labels.missingLastName}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <InputMobile
          value={mobileNumber}
          required={content.phoneNumberRequired}
          type="text"
          onChange={(e: React.FormEvent<HTMLInputElement>) => setMobileNumber(e.currentTarget.value)}
          placeholder={content?.labels.cellphone}
          pattern={content?.regex.phoneNumber}
          errorText={mobileNumber ? content?.labels.invalidCellPhone : content?.labels.missingCellPhone}
          borderStyle={BorderStyle.BlackThinSolid}
        />
        <Checkbox
          checked={termsAccepted}
          onChange={(e: React.FormEvent<HTMLInputElement>) => setTermsAccepted(e.currentTarget.checked)}
        >
          <Html html={content?.acceptTermsText?.html} />
        </Checkbox>
        <div>
          <Html html={content?.acceptTermsDescription?.html} />
        </div>
        <ButtonWrap>
          <FeedbackButton
            type="submit"
            fullWidth
            display="block"
            pendingText={content?.labels.becomeMember}
            text={content?.labels.becomeMember}
            theme={ButtonTheme.BlackToWhite}
            onClick={() => {}}
          />
        </ButtonWrap>
      </Form>
    </>
  );
  
  return (
    <>
      <BackToLoginTextWrap onClick={() => props.toLogin()}>
        {isCompact ? <Left24x24 css={{ marginRight: '12px' }} /> : <Left16x16 css={{ marginRight: '12px' }} />}
        <BackToLoginText fontSize={ResponsiveSize.D16_M16} upperCase>
          {content?.labels.backToLogin}
        </BackToLoginText>
      </BackToLoginTextWrap>
      <ScrollableWrap scrollElementRef={props.scrollElementRef}>
        <Heading fontSize={ResponsiveSize.D40_M32}>
          {content?.heading}
        </Heading>
        {formContent}
      </ScrollableWrap>
    </>
  );
};

export default RegisterForm;
