import {
  AllDataLayerActions,
  AddToCartAction,
  CartEventLocation,
  RemoveFromCartAction,
  LoginAction,
  SubscribeAction,
  PageLoadAction,
  CategoryPageLoadAction,
  CheckoutPageLoadAction,
  ProductPageLoadAction,
  VariationPageLoadAction,
  OrderConfirmationPageLoadAction,
  OrderConfirmationAction,
  RegisterAction,
  ClickInListProduct as ClickInListProductType,
  ClickInListProductAction,
  AddOrRemoveFromCartAction,
  CategoryTrackingInformationProduct,
  ImpressionsEventAction,
} from './data-layer-actions';
import { setLocalStorageItem, postJson, URLX, getLocalStorageItem } from '@polarnopyret/scope';
import TrackingInformationProductType from 'TrackingInformation/TrackingInformationProduct.type';

import State, { PageType } from 'Shared/State';
import isCheckoutPage from 'Checkout/Pages/Checkout/current-page-is-checkout';
import isVariationPage from 'Product/current-page-is-variation';
import isCategoryPage from 'Category/current-page-is-categorypage';
import isOrderConfirmationPage from 'Checkout/Pages/OrderConfirmation/current-page-is-order-confirmation';
import ProductListItemViewModel from '../Product/ProductListing/ProductListItemViewModel.type';
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

export function addToDataLayer(action: AllDataLayerActions) {
  const cb = (window as any).Cookiebot;
  if (cb) {
    // if cookiebot is present. make sure we are allowed to send data
    if (cb.hasResponse) {
      window.dataLayer.push(action);
      (window as any).dataLayerLastAction = null;
    } else {
      //log last action if the user accepts cookies
      if (action.event === "pageview") {
        (window as any).dataLayerLastAction = action;
      }
    }
  } else {
    window.dataLayer.push(action);
  }
}

export function initTagManager() {
  if (!('dataLayer' in window)) {
    (window as any).dataLayer = [];
    (window as any).dataLayerLastAction = null;
  }
}

export function initAppInsights(instrumentationKey: string, cookieConsent: boolean) {
  if (instrumentationKey && instrumentationKey !== "") {
    const appInsights = new ApplicationInsights({
      config: {
        instrumentationKey: instrumentationKey,
        disableExceptionTracking: true,
        isCookieUseDisabled: !cookieConsent,
        enableSessionStorageBuffer: true
      }
    });

    appInsights.loadAppInsights();
    (window as any).appInsights = appInsights;
  }
}

function getProductByCode(code: string): Promise<TrackingInformationProductType> {
  return postJson('/TrackingInformation/GetProductTrackingData', { code });
}

function getProductsByCodes(
  codes: Array<{ code: string; quantity: number }>,
): Promise<TrackingInformationProductType[]> {
  return postJson('/TrackingInformation/GetProductsTrackingData', { codes: codes.map(c => c.code) });
}

function productsToImpressions(
  products: ProductListItemViewModel[],
  listName: String,
  from: number = 0,
  to: number = products.length,
) {
  const impressions: CategoryTrackingInformationProduct[] = [];

  for (let i = from; i < to; i++) {
    const p = products[i];
    impressions.push({
      name: p.displayName,
      id: formatTrackingId(p.itemNumber, p.code),
      price: (p.price.sellingPrice ? p.price.sellingPrice : "0") + '',
      brand: null,
      category: p.categoryName,
      variant: p.color,
      list: listName,
      position: i + 1,
    } as CategoryTrackingInformationProduct);
  }
  return impressions;
}

function formatTrackingId(productItemNumber: String, variationCode: String) {
  if (!variationCode || !productItemNumber) {
    return productItemNumber;
  }

  const variationCodeParts = variationCode.split('_');
  return variationCodeParts.length === 2 ? productItemNumber + variationCodeParts[1] : productItemNumber;
}

export function addToCart(products: TrackingInformationProductType[], location: CartEventLocation) {
  const action = {
    event: 'addToCart',
    eventInfo: {
      category: 'ecommerce interaction',
      action: 'addToCart',
    },
    ecommerce: {
      add: {
        products: products.map(product => {
          const obj = Object.assign({}, product, {
            cartEventLocation: location,
          });
          return obj;
        }),
      },
    },
  } as AddToCartAction;

  addToCartThrottler(action);
}

let timeoutId: number = 0;
let cartActionsQueue: AddOrRemoveFromCartAction[] = [];
let trackingInformationProductsInQueue: Array<TrackingInformationProductType & {
  quantity: number;
  cartEventLocation: CartEventLocation;
}> = [];
function addToCartThrottler(action: AddOrRemoveFromCartAction) {
  cartActionsQueue.push(action);

  if (action.ecommerce.add && action.ecommerce.add.products.length > 0) {
    action.ecommerce.add.products.forEach(p => trackingInformationProductsInQueue.push(p));
  } else if (action.ecommerce.remove && action.ecommerce.remove.products.length > 0) {
    action.ecommerce.remove.products.forEach(p => trackingInformationProductsInQueue.push(p));
  }

  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = 0;
  }
  timeoutId = setTimeout(excecuteCartThrottlerQueue, 2000);
}

function excecuteCartThrottlerQueue() {
  if (!cartActionsQueue.length) {
    return;
  }
  timeoutId = 0;

  const mergedActions: AddOrRemoveFromCartAction[] = mergeCartAddActions();
  mergedActions.forEach(mergedAction => addToDataLayer(mergedAction));
  trackingInformationProductsInQueue = [];
  cartActionsQueue = [];
}

function mergeCartAddActions(): AddOrRemoveFromCartAction[] {
  const intermediate = {};
  cartActionsQueue.forEach(action => {
    if (action.ecommerce.add && action.ecommerce.add.products) {
      action.ecommerce.add.products.forEach(product => {
        if (!(product.id in intermediate)) {
          intermediate[product.id] = product.quantity;
        } else {
          intermediate[product.id] += product.quantity;
        }
      });
    } else if (action.ecommerce.remove && action.ecommerce.remove.products) {
      action.ecommerce.remove.products.forEach(product => {
        if (!(product.id in intermediate)) {
          intermediate[product.id] = -product.quantity;
        } else {
          intermediate[product.id] -= product.quantity;
        }
      });
    }
  });
  const remove = {
    event: 'removeFromCart',
    eventInfo: {
      category: 'ecommerce interaction',
      action: 'removeFromCart',
    },
    ecommerce: {
      remove: {
        products: [],
      },
    },
  } as RemoveFromCartAction;
  const add = {
    event: 'addToCart',
    eventInfo: {
      category: 'ecommerce interaction',
      action: 'addToCart',
    },
    ecommerce: {
      add: {
        products: [],
      },
    },
  } as AddToCartAction;

  for (const id in intermediate) {
    if (intermediate[id] > 0) {
      const addProduct = trackingInformationProductsInQueue.find(s => s.id === id);
      addProduct.quantity = intermediate[id];
      add.ecommerce.add.products.push(addProduct);
    } else if (intermediate[id] < 0) {
      const removeProduct = trackingInformationProductsInQueue.find(s => s.id === id);
      removeProduct.quantity = Math.abs(intermediate[id]);
      remove.ecommerce.remove.products.push(removeProduct);
    }
  }

  const result: AddOrRemoveFromCartAction[] = [];
  if (remove.ecommerce.remove.products.length > 0) {
    result.push(remove);
  }
  if (add.ecommerce.add.products.length > 0) {
    result.push(add);
  }
  return result;
}

export function addToCartByCode(code: string, location: CartEventLocation, quantity: number) {
  getProductByCode(code).then(result => addToCart([Object.assign({}, result, { quantity })], location));
}

export function addToCartByCodes(codes: Array<{ code: string; quantity: number }>, location: CartEventLocation) {
  getProductsByCodes(codes).then((result: TrackingInformationProductType[]) => {
    const products = result.map(p => {
      return Object.assign({}, p, {
        quantity: codes.find(s => s.code === p.id).quantity,
      });
    });
    addToCart(products, location);
  });
}

export function removeFromCartByCode(code: string, location: CartEventLocation, quantity: number) {
  getProductByCode(code).then(product => removeFromCart([product], location));
}

export function removeFromCart(products: TrackingInformationProductType[], location: CartEventLocation) {
  const action = {
    event: 'removeFromCart',
    eventInfo: {
      category: 'ecommerce interaction',
      action: 'removeFromCart',
    },
    ecommerce: {
      remove: {
        products: products.map(product => {
          const obj = Object.assign({}, product, {
            cartEventLocation: location,
          });
          return obj;
        }),
      },
    },
  } as RemoveFromCartAction;
  addToCartThrottler(action);
}

export function login(method: 'Username/Password' | 'Facebook') {
  const action = {
    event: 'login',
    eventInfo: {
      category: 'Login Method',
      action: method,
    },
  } as LoginAction;
  addToDataLayer(action);
}

export function subscribe(toEmail: boolean, toSMS: boolean) {
  if (!toEmail && !toSMS) {
    return;
  }
  const subscribeTo = toEmail && toSMS ? 'Email + SMS' : toEmail && !toSMS ? 'Email' : 'SMS';
  const action = {
    event: 'subscription',
    eventInfo: {
      category: 'Newsletter Signups',
      action: subscribeTo,
    },
  } as SubscribeAction;
  addToDataLayer(action);
}

export function insightsPageView(page: PageType) {
  try {
    // Add page view to application insights tracking
    if ((window as any).appInsights) {
      const ai = (window as any).appInsights;
      ai.trackPageView();
    }
  }
  catch (error) {
    window.rg4js &&
      window.rg4js('send', {
        error,
        tags: ['handled'],
      });
  }
}

export function pageLoad(page: PageType, isDynamicPageLoad: boolean, state: State) {
  let purchaseAction: OrderConfirmationPageLoadAction = null;
  let variationAction: VariationPageLoadAction = null;

  try {
    const searchParam = window.location.search
      .substr(1)
      .split('&')
      .find(q => q.substr(0, 2) === 'q=');
    const pathSufix = searchParam && searchParam.length > 0 && `?${searchParam}`;
    const path = page.url ? (pathSufix ? new URLX(page.url).pathname + pathSufix : new URLX(page.url).pathname) : '';
    let action = {
      event: 'pageview',
      pageData: {
        path,
        type: isDynamicPageLoad ? 'dynamic' : 'hard',
      },
      ecommerce: undefined,
    } as PageLoadAction;

    if (state.currentUser.trackingUser) {
      action.userData = {
        id: state.currentUser.trackingUser.id,
        registrationDate: state.currentUser.trackingUser.registrationDate,
      };
    }

    if (isCheckoutPage(page)) {
      action = Object.assign({}, action, {
        eventInfo: {
          category: 'ecommerce interaction',
          action: 'checkout',
        },
        ecommerce: {
          checkout: {
            actionField: {
              step: 1,
              emailAddress: page.customer.email,
              emailHashed: page.customer.emailHashed,
            },
            products: page.cart.trackingItems,
          },
        },
      }) as CheckoutPageLoadAction;
    } else if (isOrderConfirmationPage(page) && Boolean(page.trackingInformationTransaction)) {
      const ordersTracked = getLocalStorageItem<string[]>('tracking-information-transaction-notification-sent', []);
      const orderAlreadyTracked = Boolean(
        ordersTracked.find(s => s === page.trackingInformationTransaction.transactionId),
      );
      if (page.isFirstReceiptView && !orderAlreadyTracked) {
        const orderConfirmationAction = {
          event: 'purchase',
          pageData: {
            path: new URLX(page.url).pathname,
          },
        } as OrderConfirmationAction;

        let orderId = page.trackingInformationTransaction.transactionId;
        let orderAmount = page.trackingInformationTransaction.transactionTotalRevenue;
        let orderTaxAmount = page.trackingInformationTransaction.transactionTotalTax;
        let currency = page.trackingInformationTransaction.transactionCurrency;
        let shippingAmount = page.trackingInformationTransaction.transactionTotalShipping

        purchaseAction = Object.assign({}, orderConfirmationAction, {
          eventInfo: {
            category: 'ecommerce interaction',
          },
          ecommerce: {
            purchase: {
              actionField: {
                id: page.trackingInformationTransaction.transactionId,
                revenue: page.trackingInformationTransaction.transactionTotalRevenue,
                emailHashed: page.trackingInformationTransaction.emailHashed,
                coupon: page.trackingInformationTransaction.transactionCouponCode,
                step: 2,
              },
              products: page.trackingInformationProducts,
              ts_checkout: {
                tsCheckoutOrderNr: orderId,
                tsCheckoutBuyerEmail: page.trackingInformationTransaction.emailAddress,
                tsCheckoutOrderAmount: Number.parseFloat((Number.parseFloat(orderAmount) + Number.parseFloat(shippingAmount)).toFixed(2)),
                tsCheckoutOrderCurrency: currency,
                tsCheckoutOrderPaymentType: page.trackingInformationTransaction.paymentMethodName
              },
              apprl: {
                apprlOrderId: orderId,
                apprlOrderCurrency: currency,
                apprlOrderTotal: Number.parseFloat(orderAmount) - Number.parseFloat(orderTaxAmount),
                apprlOrderSku: page.trackingInformationProducts.map(x => `${x.id}`).join("^"),
                apprlOrderQuantity: page.trackingInformationProducts.map(x => `${x.quantity}`).join("^"),
                apprlOrderPrice: page.trackingInformationProducts.map(x => `${Number.parseFloat(x.price).toFixed(2)}`).join("^")
              }
            },
          }
        }) as OrderConfirmationPageLoadAction;
        setLocalStorageItem('tracking-information-transaction-notification-sent', [
          ...ordersTracked,
          page.trackingInformationTransaction.transactionId,
        ]);
      }
    } else if (isVariationPage(page)) {
      const variationEventAction = {
        event: 'productdetail',
        pageData: {
          path: new URLX(page.url).pathname,
        },
      } as VariationPageLoadAction;

      const products: TrackingInformationProductType[] = [];
      products.push(page.trackingProduct);
      variationAction = Object.assign({}, variationEventAction, {
        eventInfo: {
          category: 'ecommerce interaction',
          action: 'detail',
        },
        ecommerce: {
          detail: {
            products: products,
          },
          purchase: undefined,
        },
      }) as ProductPageLoadAction;
    } else if (isCategoryPage(page)) {
      const perPage = 30;
      const numProducts = page.productList.products.length;

      let from = 0;
      if (isDynamicPageLoad) {
        from = Math.max(numProducts - perPage, 0);
      }

      const impressions = productsToImpressions(page.productList.products, page.pageName, from, numProducts);
      action = Object.assign({}, action, {
        ecommerce: { currencyCode: state.appShellData.market.currency, impressions },
      }) as CategoryPageLoadAction;
    }

    addToDataLayer(action);

    if (purchaseAction) {
      addToDataLayer(purchaseAction);
    }
    if (variationAction) {
      addToDataLayer(variationAction);
    }
  } catch (error) {
    window.rg4js &&
      window.rg4js('send', {
        error,
        tags: ['handled'],
      });
    console.error(error);
  }
}

export function register() {
  const action = {
    event: 'newAccount',
  } as RegisterAction;
  addToDataLayer(action);
}

export function saveEmailToLocalStorage(email: string, emailHashed: string) {
  setLocalStorageItem('emailAddress', email);
  setLocalStorageItem('emailHashed', emailHashed);
}

export function productClickInList(product: ProductListItemViewModel, category: string, position: number) {
  const products: ClickInListProductType[] = [
    {
      name: product.displayName,
      id: formatTrackingId(product.itemNumber, product.code),
      price: product.price.sellingPrice.toString(),
      brand: null,
      category,
      variant: product.color,
      position,
    } as ClickInListProductType,
  ];

  const action = {
    event: 'productClick',
    ecommerce: {
      click: {
        products,
      },
    },
  } as ClickInListProductAction;
  addToDataLayer(action);
}

export function genericImpressions(products: ProductListItemViewModel[], listName: string) {
  const impressions = productsToImpressions(products, listName);
  const action = {
    event: 'impressions',
    ecommerce: {
      impressions: impressions
    },
  } as ImpressionsEventAction;

  addToDataLayer(action);
}
